.lcontainer {
    height: 100vh;
    width: 100vw;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif
}

.loader {
    height: 20px;
    width: 250px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}
.loader--dot {
    animation-name: loader;
    animation-timing-function: ease-in-out;
    animation-duration: 4s;
    animation-iteration-count: infinite;
    height: 20px;
    width: 20px;
    border-radius: 100%;
    background-color: black;
    position: absolute;
    border: 2px solid white;
}
.loader--dot:first-child {
    background-color: #436556;
    animation-delay: 0.5s;
}
.loader--dot:nth-child(2) {
    background-color: #ffffff;
    border: 1px solid #436556;
    animation-delay: 0.4s;
}
.loader--dot:nth-child(3) {
    background-color: #436556;
    animation-delay: 0.3s;
}
.loader--dot:nth-child(4) {
    background-color: #ffffff;
    border: 1px solid #436556;
    animation-delay: 0.2s;
}
.loader--dot:nth-child(5) {
    background-color: #436556;
    animation-delay: 0.1s;
}
.loader--dot:nth-child(6) {
    background-color: #ffffff;
    border: 1px solid #436556;
    animation-delay: 0s;
}
.loader--text {
    position: absolute;
    top: 200%;
    left: 0;
    right: 0;
    width: 8rem;
    font-size: 2rem;
    margin: auto;
}
.loader--text:after {
    content: "Loading";
    /*font-weight: bold;*/
    animation-name: loading-text;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    color: #3b3b3b;
}

.logout--text {
    position: absolute;
    top: 200%;
    left: 0;
    right: 0;
    width: 8rem;
    font-size: 2rem;
    margin: auto;
}
.logout--text:after {
    content: "Logging Out";
    /*font-weight: bold;*/
    animation-name: logout-text;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    color: #3b3b3b;
}

@keyframes loader {
    15% {
        transform: translateX(0);
    }
    45% {
        transform: translateX(230px);
    }
    65% {
        transform: translateX(230px);
    }
    95% {
        transform: translateX(0);
    }
}
@keyframes loading-text {
    0% {
        content: "Loading";
    }
    25% {
        content: "Loading.";
    }
    50% {
        content: "Loading..";
    }
    75% {
        content: "Loading...";
    }
    100% {
        content: "Loading....";
    }
}

@keyframes logout-text {
    0% {
        content: "Logging Out";
    }
    25% {
        content: "Logging Out.";
    }
    50% {
        content: "Logging Out..";
    }
    75% {
        content: "Logging Out...";
    }
    100% {
        content: "Logging Out....";
    }
}